<template>
	<div v-if="expenses.length">
		<div v-for="e in expenses" :key="e.id">
			<div class="row" style="margin-top: 1rem;">
				<div class="col">
					<div class="card shadow-sm">
						<div class="row g-0">
							<div class="col-md-1">
								<a v-bind:href="`https://opencollective.com/${e.toAccount.slug}`" target="_blank">
									<img v-bind:src="`${e.toAccount.imageUrl}`" class="img-fluid rounded-start" v-bind:alt="`${e.toAccount.name} Request`">
								</a>
							</div>
							<div class="col-md-7">
								<div class="card-body">
									<h5 class="card-title">
										<a v-if="e.expense?.legacyId" v-bind:href="`https://opencollective.com/${account.slug}/expenses/${e.expense.legacyId}`" target="_blank">[[e.description]]</a> 
										<a v-else v-bind:href="`https://opencollective.com/${account.slug}`" target="_blank">[[e.description]]</a> 
										<span class="badge bg-success" style="margin-left: 0.5rem;">PAID</span>
									</h5>
									<p class="card-text">[[ truncate(e.dynamic_description, 240) ]] • [[ humanizeDatetime(e.createdAt) ]]</p>
								</div>
							</div>
							<div class="col-md-2">
								<div class="card-body">
									<a v-if="e.expense?.items" v-bind:href="`${ ifNot(first(e.expense.items).url, ocURL(account.slug, e.expense.legacyId)) }`" target="_blank" style="margin-top: 1.75rem;"><h5 class="card-title" style="font-size: 3rem;">🧾</h5></a>
									<a v-else v-bind:href="`https://opencollective.com/${account.slug}/expenses`" target="_blank" style="margin-top: 1.75rem;"><h5 class="card-title" style="font-size: 3rem;">🧾</h5></a>
								</div>
							</div>
							<div class="col-md-2">
								<div class="card-body">
									<h5 class="card-title" style="margin-top: 1rem; color: #f3587d;">- [[ currency(abs(e.amount.value)) ]] USD</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	});

	export default {
		name: 'ExpenseList',
		props: {
			expenses: {
				type: Array,
				required: true,
			},
			account: {
				type: Object,
				required: true,
			}
		},
		methods: {
			ifNot(a, b) {
				return a ?? b;
			},
			abs(integer) {
				return Math.abs(integer);
			},
			first(list) {
				return list[0];
			},
			ocURL(slug, id) {
				return `https://opencollective.com/${slug}/expenses/${id}`;
			},
			getItem(obj, key) {
				return obj[key]
			},
			truncate(string, chars) {
				return (string.length > chars) ? string.slice(0, chars-1) + '&hellip;' : string;
			},
			currency(amount) {
				return formatter.format(amount);
			},
			humanizeDatetime(dt) {
				return new Date(dt).toDateString();
			}
		}
	}
</script>