<template>
	<div class="toast-container position-relative pb-3 top-0 start-50 translate-middle-x" style="z-index: 9999999999 !important;">
		<div :class="{ 'bg-danger': isError, 'bg-success': !isError }" class="toast align-items-center text-white border-0" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="15000">
			<div class="d-flex">
				<div class="toast-body">
					[[msg]]
				</div>
				<button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ToastNotification',
		props: {
			msg: {
				type: String,
				required: true,
			},
			isError: {
				type: Boolean,
				required: true
			}
		},
	}
</script>