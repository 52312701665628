<template>
	<toast-notification v-if="errors" :msg="this.errors" :isError="true"></toast-notification>
	<div v-if="loaded">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
			<li class="breadcrumb-item"><a href="/">Home</a></li>
			<li v-if="account.parentAccount" class="breadcrumb-item"><a v-bind:href="`/funds/host/${account.parentAccount.slug}`">[[account.parentAccount.name]]</a></li>
			<li class="breadcrumb-item"><a v-bind:href="`/funds/${$route.params.type}/${$route.params.slug}`">[[account.name]] Fund</a></li>
			<li class="breadcrumb-item active" aria-current="page">[[title]]</li>
			</ol>
		</nav>
		<div class="card shadow-sm p-3 embed" >
			<div class="card-body embed">
				<div v-if="account.slug.includes('gatherfor')">
					<div class="row" style="margin-top: 1rem; padding-left: 1rem; padding-right: 1rem;">
						<div class="col-md-4 offset-md-4">
							<a href="#" data-bs-toggle="modal" data-bs-target="#Cashapp-Modal" class="sleek-button approve-button" style="font-size: 0.75rem; display: inline-block; width: 100%; border: 0px; box-shadow: none;">Looking for Cash App?</a>
							<a href="#" data-bs-toggle="modal" data-bs-target="#Zelle-Modal" class="sleek-button" style="font-size: 0.75rem; display: inline-block; width: 100%; border: 0px; box-shadow: none;">Looking for Zelle?</a>
							<!-- Cashapp Modal -->
							<div class="modal fade" id="Cashapp-Modal" tabindex="-1" aria-labelledby="Cashapp-ModalLabel" aria-hidden="true">
								<div class="modal-dialog modal-dialog-centered" >
									<div class="modal-content">
										<div class="modal-header">
											<h5 class="modal-title" id="Cashapp-ModalLabel"><span style="color: grey;">$GatherFor</span> on Cash App</h5>
											<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
												<span aria-hidden="true"></span>
											</button>
										</div>
										<div class="modal-body">
											<img src="@/assets/cashapp-qr.png" class="img-fluid rounded-start" alt="Scan to pay GatherFor on Cash App" style="margin-left: auto; margin-right: auto; display: block;">
										</div>
										<div class="modal-footer">
											<button type="button" class="sleek-button secondary-button" data-bs-dismiss="modal" style="font-size: 0.75rem;">Dismiss</button>
											<a id="cashapp-link" class="sleek-button approve-button" style="font-size: 0.75rem;" href="https://cash.app/$GatherFor">Pay on Cash App</a>
										</div>
									</div>
								</div>
							</div>
							<!-- Zelle Modal -->
							<div class="modal fade" id="Zelle-Modal" tabindex="-1" aria-labelledby="Zelle-ModalLabel" aria-hidden="true">
								<div class="modal-dialog modal-dialog-centered" >
									<div class="modal-content">
										<div class="modal-header">
											<h5 class="modal-title" id="Zelle-ModalLabel"><span style="color: grey;">Teju@gatherfor.org</span> on Zelle</h5>
											<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
												<span aria-hidden="true"></span>
											</button>
										</div>
										<div class="modal-body">
											Send zelle to teju@gatherfor.org.
										</div>
										<div class="modal-footer">
											<button type="button" class="sleek-button secondary-button" data-bs-dismiss="modal" style="font-size: 0.75rem;">Dismiss</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-fluid embed">
					<iframe v-bind:src="`${tier_url}?interval=oneTime&platformContribution=0&amount=50&shouldRedirectParent=true&contributeAs=me&tags=app&useTheme=true&hideFAQ=true&hideHeader=true&redirect=https://pool.gatherfor.org/funds/${$route.params.type}/${$route.params.slug}?success=true`" sandbox="allow-top-navigation allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-scripts allow-same-origin" style="width: 100%; min-height: 100vh;"></iframe>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="loading">
		<img src='@/assets/ajax-loader.gif' alt="Loading..." class="loading-image" />
	</div>
</template>

<script>
	import ToastNotification from "@/components/ToastNotification.vue";
	export default {
		components: { ToastNotification },
		name: "ContributeView",
		data() {
			return {
				account: {},
				user: {},
				tier_url: null,
				title: null,
				is_authenticated: false,
				loaded: false,
				errors: null
			}
		},
		created() {
			this.axios.get(`/api/${this.$route.params.type}/${this.$route.params.slug}/contribute`)
				.then((res) => res.data)
				.then((result) => {
					document.title = result.title;
					this.title = result.title;
					this.account = result.account;
					this.user = result.user;
					this.is_authenticated = result.user?.auth_info.isAuthenticated;
					this.tier_url = result.tier_url;
					this.loaded = true;

					if (!this.is_authenticated && this.account.slug != 'papertree-bedstuy' && this.account.slug != 'papertree-example') {
						window.location.replace("/?err=403");
					}
				})
				.catch((error) => {
					this.errors = error.message;
				});
		},
		updated() {
			window.$(".toast").toast('show');
		}
	}
</script>

<style>
	.loading {
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.7;
		background-color: #fff;
		z-index: 99;
	}

	.loading-image {
		z-index: 100;
	}
</style>