<template>
	<div class="row" style="margin-top: 2.5rem;">
		<div class="col-md-2"></div>
		<div class="col-md-8">
			<h2 v-if="funds.length" class="h3" style="font-weight: 300; text-align: center;">Select a fund below</h2>
            <h2 v-else class="h3" style="font-weight: 300; text-align: center;">Looks like your team isn't signed up yet. Contact us to get Papertree for your team.</h2>
		</div>
		<div class="col-md-2"></div>
	</div>

	<div v-if="funds.length">
		<div class="row" style="margin-top: 1rem;" v-for="f in funds" :key="f.slug">
			<div class="col-md-2"></div>
			<div class="col-md-8">
				<div class="dropdown" v-if="f.hostOf.length">
					<a class="sleek-button dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
						[[f.name]]
					</a>

					<ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
						<li><a class="dropdown-item sleek-button secondary-button" :href="`/funds/${f.pt_type}/${f.slug}`">[[f.name]] ([[f.pt_type]])</a></li>
						<li v-for="h in f.hostOf" :key="h.slug"><a class="dropdown-item sleek-button secondary-button" :href="`/funds/${h.pt_type}/${h.slug}`">[[h.name]]</a></li>
					</ul>
				</div>
				<a :href="`/funds/${f.pt_type}/${f.slug}`" class="sleek-button" v-else>[[f.name]]</a>
			</div>
			<div class="col-md-2"></div>
		</div>
	</div>

	<div v-else>
		<div class="row" style="margin-top: 1rem;">
			<div class="col-md-2"></div>
			<div class="col-md-8">
				<a href="https://calendly.com/rudyfraser/chat-with-rudy" class="sleek-button">Contact Us</a>
			</div>
			<div class="col-md-2"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FundIndex',
		props: {
			funds: {
				type: Array,
				required: true,
			},
		},
	}
</script>