<template>
	<toast-notification v-if="errors" :errors="this.errors"></toast-notification>
	<div v-if="loaded">
		<div class="container-fluid">
			<div class="row" style="margin-bottom: 1.5rem;">
				<div class="col-md-8 offset-md-2">
					<h1 class="h2 card-title" style="display: inline-block;">Welcome back! Your available balance is [[ currency(balance) ]].</h1>
				</div>
			</div>
			<div class="row" style="margin-bottom: 1.5rem;">
				<div class="col-md-8 offset-md-2">
					<ul class="nav nav-pills" id="dashboard-tabs" role="tablist">
						<li class="nav-item" role="presentation">
						<button class="nav-link active" id="contributions-tab" data-bs-toggle="tab" data-bs-target="#contributions" type="button" role="tab" aria-controls="contributions" aria-selected="true">Contributions</button>
						</li>
						<li class="nav-item" role="presentation">
						<button class="nav-link" id="expenses-tab" data-bs-toggle="tab" data-bs-target="#expenses" type="button" role="tab" aria-controls="expenses" aria-selected="false">Expenses</button>
						</li>
					</ul>
					<div class="tab-content" id="dashboards">
						<div class="tab-pane fade show active" id="contributions" role="tabpanel" aria-labelledby="contributions-tab"></div>
						<div class="tab-pane fade" id="expenses" role="tabpanel" aria-labelledby="expenses-tab"></div>
					</div>
				</div>
			</div>
			<div class="row" style="margin-bottom: 1.5rem;">
				<div class="col-md-8 offset-md-2">
					<p>To see more details you can <a href="/" class="transactions-link" style="margin: 1em 0; display: inline;">view all teams →</a></p>
				</div>
			</div>

			<div v-if="pending_expenses.length">
				<div class="row" style="margin-bottom: 1.5rem;">
					<div class="col-md-8 offset-md-2">
						<h3 class="h3" style="font-weight: 300;">Pending Requests</h3>
					</div>
				</div>
				<pending-expense-list :pending_expenses="this.pending_expenses" :votes="this.votes" :user="this.user"></pending-expense-list>
			</div>
		</div>
	</div>
	<div v-else class="loading">
		<img src='@/assets/ajax-loader.gif' alt="Loading..." class="loading-image" />
	</div>
</template>

<script>
	import ToastNotification from "@/components/ToastNotification.vue";
	import PendingExpenseList from "@/components/PendingExpenseList.vue";
	import { difference, flatten, uniq } from 'lodash';
	import dayjs from 'dayjs';
	import ApexCharts from 'apexcharts'
	var utc = require('dayjs/plugin/utc')
	dayjs.extend(utc);

	const alignSeries = (series, sort = (a, b) => new Date(a.x) - new Date(b.x)) => {
		const indexesBySeries = series.map(singleSeries => singleSeries.data.map(d => d.x));
		const uniqueIndexes = uniq(flatten(indexesBySeries));
		indexesBySeries.forEach((el, idx) => {
			const missingIndexes = difference(uniqueIndexes, indexesBySeries[idx]);
			if (missingIndexes.length) {
				series[idx].data.push(...missingIndexes.map(x => ({ x, y: 0 })));
			}
			series[idx].data.sort(sort);
		});
		return series;
	};
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	});
	export default {
		components: { PendingExpenseList, ToastNotification },
		name: "DashboardView",
		methods: {
			currency(amount) {
				return formatter.format(amount);
			}
		},
		data() {
			return {
				user: {},
				loaded: false,
				balance: 0,
				con_time_unit: "MONTH",
				con_series: [],
				exp_time_unit: "MONTH",
				exp_series: [],
				pending_expenses: [],
				votes: {},
				errors: null
			}
		},
		created() {
			this.axios.get("/api/dashboard")
				.then((res) => res.data)
				.then((result) => {
					this.balance = result.balance;
					this.con_time_unit = result.con_time_unit;
					this.con_series = result.con_series;
					this.exp_time_unit = result.exp_time_unit;
					this.exp_series = result.exp_series;
					this.pending_expenses = result.pending_expenses;
					this.votes = result.votes;
					this.user = result.user;
					this.loaded = true;
				})
				.catch((error) => {
					this.errors = error.message;
				});
		},
		updated() {
			window.$(".toast").toast('show');
			const con_time_unit = this.con_time_unit;
			const exp_time_unit = this.exp_time_unit;
			let options = {
				chart: {
					type: 'area'
				},
				series: alignSeries(this.con_series),
				legend: {
					show: true,
					horizontalAlign: 'left',
					fontSize: '16px',
					fontFamily: 'inherit',
					fontWeight: 500,
					markers: {
						width: 6,
						height: 24,
						radius: 8,
						offsetY: 6,
					},
				},
				colors: ['#A3F89C', '#FFBF5F', '#8FC7FF', '#F89CE4', '#F89C9C'],
				grid: {
					xaxis: { lines: { show: true } },
					yaxis: { lines: { show: false } },
				},
				stroke: {
					curve: 'straight',
					width: 1.5,
				},
				dataLabels: {
					enabled: false,
				},
				xaxis: {
					labels: {
						formatter: function (value) {
							// Show data aggregated yearly
							if (con_time_unit === 'YEAR') {
								return dayjs(value).utc().year();
								// Show data aggregated monthly
							} else if (con_time_unit === 'MONTH') {
								return dayjs(value).utc().format('MMM-YYYY');
								// Show data aggregated by week or day
							} else if (con_time_unit === 'WEEK' || con_time_unit === 'DAY') {
								return dayjs(value).utc().format('DD-MMM-YYYY');
							}
						},
					},
				},
				yaxis: {
					labels: {
						formatter: value => formatter.format(value),
					},
				},
			};

			var con_chart = new ApexCharts(document.querySelector("#contributions"), options);
			let exp_options = {
				chart: {
					type: 'bar',
					stacked: true
				},
				series: alignSeries(this.exp_series),
				legend: {
					show: true,
					horizontalAlign: 'left',
					fontSize: '16px',
					fontFamily: 'inherit',
					fontWeight: 500,
					markers: {
						width: 6,
						height: 24,
						radius: 8,
						offsetY: 6,
					},
				},
				colors: ['#A3F89C', '#FFBF5F', '#8FC7FF', '#F89CE4', '#F89C9C'],
				grid: {
					xaxis: { lines: { show: true } },
					yaxis: { lines: { show: false } },
				},
				stroke: {
					curve: 'straight',
					width: 1.5,
				},
				dataLabels: {
					enabled: false,
				},
				xaxis: {
					labels: {
						formatter: function (value) {
							// Show data aggregated yearly
							if (exp_time_unit === 'YEAR') {
								return dayjs(value).utc().year();
								// Show data aggregated monthly
							} else if (exp_time_unit === 'MONTH') {
								return dayjs(value).utc().format('MMM-YYYY');
								// Show data aggregated by week or day
							} else if (exp_time_unit === 'WEEK' || exp_time_unit === 'DAY') {
								return dayjs(value).utc().format('DD-MMM-YYYY');
							}
						},
					},
				},
				yaxis: {
					labels: {
						formatter: value => formatter.format(value),
					},
				},
			};
			var exp_chart = new ApexCharts(document.querySelector("#expenses"), exp_options);
			con_chart.render();
			exp_chart.render();
		}
	};
</script>

<style>
	.loading {
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.7;
		background-color: #fff;
		z-index: 99;
	}

	.loading-image {
		z-index: 100;
	}

</style>