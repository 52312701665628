<template>
	<div v-if="contributions.length">
		<div v-for="c in contributions" :key="c.id">
			<div class="row" style="margin-top: 1rem;">
				<div class="col">
					<div class="card shadow-sm">
						<div class="row g-0">
							<div class="col-md-1">
								<a v-bind:href="`https://opencollective.com/${c.fromAccount.slug}`" target="_blank">
									<img v-if="c.fromAccount.name == 'Guest'" src="@/assets/default-guest-logo.svg" class="img-fluid rounded-start" v-bind:alt="`${c.fromAccount.name} Contribution`">
									<img v-else v-bind:src="`${c.fromAccount.imageUrl}`" class="img-fluid rounded-start" v-bind:alt="`${c.fromAccount.name} Contribution`">
								</a>
							</div>
							<div class="col-md-9">
								<div class="card-body">
									<h5 class="card-title">[[c.description]]</h5>
									<p class="card-text">[[ truncate(c.dynamic_description, 240) ]] • [[ humanizeDatetime(c.createdAt) ]]</p>
								</div>
							</div>
							<div class="col-md-2">
								<div class="card-body">
									<h5 class="card-title" style="margin-top: 1rem; color: #00ca9a;">+ [[ currency(c.amount.value) ]] USD</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	});

	export default {
		name: 'ContributionList',
		props: {
			contributions: {
				type: Array,
				required: true,
			},
		},
		methods: {
			truncate(string, chars) {
				return (string.length > chars) ? string.slice(0, chars-1) + '&hellip;' : string;
			},
			currency(amount) {
				return formatter.format(amount);
			},
			humanizeDatetime(dt) {
				return new Date(dt).toDateString();
			}
		}
	}
</script>