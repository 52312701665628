<template>
	<div v-if="pending_expenses.length">
		<div v-for="p in pending_expenses" :key="p.id">
			<div class="row" style="margin-top: 1rem;">
				<div class="col">
					<div class="card shadow-sm">
						<div class="row g-0">
							<div class="col-md-1">
								<a v-bind:href="`https://opencollective.com/${p.payee.slug}`" target="_blank">
									<img v-bind:src="[[p.payee.imageUrl]]" class="img-fluid rounded-start" v-bind:alt="`${p.payee.name} Request`">
								</a>
							</div>
							<div class="col-md-4">
								<div class="card-body">
									<h5 class="card-title">
										<a v-bind:href="`https://opencollective.com/${p.account.slug}/expenses/${p.legacyId}`" target="_blank">[[p.description]]</a>
										<span class="badge" :class="{ 'bg-primary': p.status != 'PENDING', 'bg-warning': p.status == 'PENDING', 'text-dark':  p.status == 'PENDING'}" style="margin-left: 0.5rem;">[[p.status]]</span>
									</h5>
									<p class="card-text">Pending request from [[p.payee.name]] to [[p.account.name]] - [[ truncate(p.description, 240) ]] • [[humanizeDatetime(p.createdAt)]]</p>
								</div>
							</div>
							<div class="col-md-1">
								<div class="card-body">
									<a v-if="p.items" v-bind:href="`${ ifNot(first(p.items).url, ocURL(p.account.slug, p.legacyId)) }`" target="_blank" style="margin-top: 1.75rem;"><h5 class="card-title" style="font-size: 3rem;">🧾</h5></a>
									<a v-else v-bind:href="`https://opencollective.com/${p.account.slug}/expenses/${p.legacyId}`" target="_blank" style="margin-top: 1.75rem;"><h5 class="card-title" style="font-size: 3rem;">🧾</h5></a>
								</div>
							</div>
							<div class="col-md-4">
								<div class="card-body" style="display: inline-block;">
									<p v-if="p.status == 'PENDING'" style="text-align: center;">
										<small>[[ getItem(votes, p.id).vote_count ]] vote(s) received from [[getItem(votes, p.id).voter_names_string]].</small>
									</p>
									<p v-else style="text-align: center;"><small>All votes received. Payout expected in 3-5 business days.</small></p>

									<button type="button" data-bs-toggle="modal" v-bind:data-bs-target="`#expense-${p.id}-Approval-Modal`" :disabled="getItem(votes, p.id)?.voters?.includes(user.id) || p.status != 'PENDING' || !user.auth_info.isAuthenticated ? true : null" class="sleek-button approve-button" style="font-size: 0.75rem; display: inline-block; margin-right: 0.75rem;">Approve</button>
									<button type="button" data-bs-toggle="modal" v-bind:data-bs-target="`#expense-${p.id}-Rejection-Modal`" :disabled="getItem(votes, p.id)?.voters?.includes(user.id) || p.status != 'PENDING' || !user.auth_info.isAuthenticated ? true : null"	class="sleek-button reject-button" style="font-size: 0.75rem; display: inline-block;">Disapprove</button>
								</div>
							</div>
							<div class="col-md-2">
								<div class="card-body">
									<h5 class="card-title" style="margin-top: 1rem; color: grey;">([[ currency(abs(p.amountV2.value)) ]]) USD</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Rejection Modal -->
			<form v-bind:action="`/funds/${p.account.pt_type}/${p.account.slug}/request/reject`" method="post">
				<div class="modal fade" v-bind:id="`expense-${p.id}-Rejection-Modal`" tabindex="-1" v-bind:aria-labelledby="`expense-${p.id}-Rejection-ModalLabel`" aria-hidden="true">
					<div class="modal-dialog modal-dialog-centered" >
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" v-bind:id="`expense-${p.id}-Rejection-ModalLabel`">Confirm Disapproval</h5>
								<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
									<span aria-hidden="true"></span>
								</button>
							</div>
							<div class="modal-body">
								Are you sure you want to deny [[p.payee.name]]'s request for [[ currency(abs(p.amountV2.value)) ]]? Doing so will cancel any approvals for this request.
							</div>
							<div class="modal-footer">
								<button type="button" class="sleek-button secondary-button" data-bs-dismiss="modal" style="font-size: 0.75rem;">Cancel</button>
								<input type="submit" value="Disapprove" class="sleek-button reject-button" style="font-size: 0.75rem;">
								<input type="hidden" name="expense_id" v-bind:value="`${p.id}`">
								<input type="hidden" name="expense_legacy_id" v-bind:value="`${p.legacyId}`">
								<input type="hidden" name="user_name" v-bind:value="`${user.name}`">
								<input type="hidden" name="user_id" v-bind:value="`${user.id}`">
								<input type="hidden" name="collective_id" v-bind:value="`${p.account.id}`">
								<input type="hidden" name="decision" value="AGAINST">
								<input type="hidden" name="csrfmiddlewaretoken" v-bind:value="`${getCsrfToken()}`">
							</div>
						</div>
					</div>
				</div>
			</form>

			<!-- Approval Modal -->
			<form v-bind:action="`/funds/${p.account.pt_type}/${p.account.slug}/request/approve`" method="post">
				<div class="modal fade" v-bind:id="`expense-${p.id}-Approval-Modal`" tabindex="-1" v-bind:aria-labelledby="`expense-${p.id}-Approval-ModalLabel`" aria-hidden="true">
					<div class="modal-dialog modal-dialog-centered" >
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" v-bind:id="`expense-${p.id}-Approval-ModalLabel`">Confirm Approval</h5>
								<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
									<span aria-hidden="true"></span>
								</button>
							</div>
							<div class="modal-body">
								Are you sure you would like to approve [[p.payee.name]]'s request for [[ currency(abs(p.amountV2.value)) ]]?
							</div>
							<div class="modal-footer">
								<button type="button" class="sleek-button secondary-button" data-bs-dismiss="modal" style="font-size: 0.75rem;">Cancel</button>
								<input type="submit" value="Approve" class="sleek-button approve-button" style="font-size: 0.75rem;">
								<input type="hidden" name="expense_id" v-bind:value="`${p.id}`">
								<input type="hidden" name="expense_legacy_id" v-bind:value="`${p.legacyId}`">
								<input type="hidden" name="user_name" v-bind:value="`${user.name}`">
								<input type="hidden" name="user_id" v-bind:value="`${user.id}`">
								<input type="hidden" name="collective_id" v-bind:value="`${p.account.id}`">
								<input type="hidden" name="decision" value="FOR">
								<input type="hidden" name="csrfmiddlewaretoken" v-bind:value="`${getCsrfToken()}`">
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import Cookies from 'js-cookie'

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	});

	export default {
		name: 'PendingExpenseList',
		props: {
			pending_expenses: {
				type: Array,
				required: true,
			},
			votes: {
				type: Object,
				required: true,
			},
			user: {
				type: Object,
				required: true,
			},
		},
		methods: {
			truncate(string, chars) {
				return (string.length > chars) ? string.slice(0, chars-1) + '&hellip;' : string;
			},
			currency(amount) {
				return formatter.format(amount);
			},
			abs(integer) {
				return Math.abs(integer);
			},
			ifNot(a, b) {
				return a ?? b;
			},
			first(list) {
				return list[0];
			},
			ocURL(slug, id) {
				return `https://opencollective.com/${slug}/expenses/${id}`;
			},
			getItem(obj, key) {
				return obj[key]
			},
			getCsrfToken() {
				return Cookies.get('csrftoken');
			},
			humanizeDatetime(dt) {
				return new Date(dt).toDateString();
			}
		}
	}
</script>