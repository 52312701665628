<template>
	<toast-notification v-if="errors" :msg="this.errors" :isError="true"></toast-notification>
	<div v-if="loaded">
		<div class="row" style="margin-top: 2.5rem;">
			<div class="col-md-2"></div>
			<div class="col-md-8">
				<h1 v-if="is_authenticated" style="text-align: center;">Welcome, [[user.name]]</h1>
				<h1 v-else style="text-align: center;">Welcome!</h1>
			</div>
			<div class="col-md-2"></div>
		</div>

		<div v-if="!is_authenticated">
			<div style="margin-top: 2.5rem;">
				<div class="row" style="margin-top: 2.5rem;">
					<div class="col-md-2"></div>
					<div class="col-md-8">
						<h3 class="h3" style="font-weight: 400;">Access your shared balance and collaborate with your team.</h3>
						<h3 class="h3" style="font-weight: 400;">Login with your Open Collective account information.</h3>
						<h6 class="h6" style="font-weight: 400;">If you don’t already have an account, click ‘Sign up’ below to create one.</h6>
					</div>
					<div class="col-md-2"></div>
				</div>

				<div class="row" style="margin-top: 1rem;">
					<div class="col-md-2"></div>
					<div class="col-md-8">
						<a id="login-link" class="sleek-button" href="/login">Login</a>
					</div>
					<div class="col-md-2"></div>
				</div>

				<div class="row" style="margin-top: 1rem;">
					<div class="col-md-2"></div>
					<div class="col-md-8">
						<a id="signup-link" class="sleek-button secondary-button" style="display: block;" href="https://opencollective.com/create-account">Sign Up</a>
					</div>
					<div class="col-md-2"></div>
				</div>
			</div>
		</div>

		<div v-else>
			<fund-index :funds="this.funds"></fund-index>
		</div>

	</div>

	<div v-else class="loading">
		<img src='@/assets/ajax-loader.gif' alt="Loading..." class="loading-image" />
	</div>

</template>

<script>
	// @ is an alias to /src
	import FundIndex from "@/components/FundIndex.vue";
	import ToastNotification from "@/components/ToastNotification.vue";

	export default {
		components: { FundIndex, ToastNotification },
		name: "HomeView",
		data() {
			return {
				funds: [],
				user: {},
				errors: null,
				is_authenticated: false,
				loaded: false
			}
		},
		created() {
			this.axios.get("/api/index")
				.then((res) => res.data)
				.then((result) => {
					this.funds = result.funds;
					this.user = result.user;
					this.is_authenticated = result.user.auth_info.isAuthenticated;
					this.loaded = true;
				})
				.catch((error) => {
					this.errors = error.message;
				});
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const errs = urlParams.get('err');
			this.errors = errs == '403' ? 'You need to be logged in to do that' : null;
		},
		updated() {
			window.$(".toast").toast('show');
		}
	};
</script>

<style>
	.loading {
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.7;
		background-color: #fff;
		z-index: 99;
	}

	.loading-image {
		z-index: 100;
	}

</style>