<template>
	<toast-notification v-if="errors" :msg="this.errors" :isError="true"></toast-notification>
	<div v-if="loaded">
		<toast-notification v-if="paid_redirect" :msg="'Your payment was a success! &#x1F389; Check your email for details.'" :isError="false"></toast-notification>

		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item"><a href="/">Home</a></li>
				<li v-if="account.parentAccount" class="breadcrumb-item"><a v-bind:href="`/funds/host/${account.parentAccount.slug}`">[[account.parentAccount.name]]</a></li>
				<li class="breadcrumb-item active" aria-current="page">[[title]]</li>
			</ol>
		</nav>
		<div class="card shadow-sm p-3" >
			<div class="card-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-md-1">
							<a id="account-link" v-bind:href="`https://opencollective.com/${$route.params.slug}`" target="_blank">
								<img v-bind:src="`${account.imageUrl}`" class="img-fluid rounded-start" v-bind:alt="`${account.name} Balance`">
							</a>
						</div>
						<div class="col-md-3">
							<h1 class="h2 card-title">Balance</h1>
						</div>
						<div class="col-md-2">
							<h1 class="h2 card-title" style="display: inline-block; margin-right: 0.75rem;">[[ currency(account.stats.balanceWithBlockedFunds.value) ]]</h1>
						</div>
						<div class="col-md-4">
							<a id="submit-request-link" v-bind:href="`/funds/${$route.params.type}/${$route.params.slug}/request`" class="sleek-button secondary-button" :class="{disabled: !is_authenticated}" style="font-size: 0.75rem; display: inline-block; margin-right: 0.75rem;" :tabindex="!is_authenticated ? '-1' : null" role="button" :aria-disabled="!is_authenticated ? true : null">Submit Request</a>
							<a id="contribution-link" v-bind:href="`/funds/${$route.params.type}/${$route.params.slug}/contribute`" class="sleek-button" style="font-size: 0.75rem; display: inline-block; width: 159px;">Add Funds</a>
						</div>
						<div class="col-md-2">
							<h1 class="h2 card-title">
								<a v-bind:href="`https://opencollective.com/${$route.params.slug}/transactions`" target="_blank" class="transactions-link">View all transactions →</a>
							</h1>
						</div>
					</div>
					<div v-if="pending_expenses.length">
						<hr style="margin-bottom: 2rem;">
						<h3 class="h3" style="font-weight: 300;">Pending Requests</h3>
						<pending-expense-list :pending_expenses="this.pending_expenses" :votes="this.votes" :user="this.user"></pending-expense-list>
					</div>

					<hr style="margin-bottom: 2rem;">
					<h3 class="h3" style="font-weight: 300;">Recent Contributions</h3>
					<div v-if="parse_nodes(account.contributions.nodes).length">
						<contribution-list :contributions="parse_nodes(this.account.contributions.nodes)"></contribution-list>
					</div>
					<div v-else class="row" style="margin-top: 1rem;">
						<div class="col-md-4"></div>
						<div class="col-md-4">
							<figure>
								<img src="@/assets/getting-started2.svg" style="max-height: 480px; max-width: 480px;" alt="Nothing here yet">
								<figcaption class="h5">No contributions yet.</figcaption>
							</figure>
						</div>
						<div class="col-md-4"></div>
					</div>

					<hr style="margin-top: 2rem; margin-bottom: 2rem;">
					<h3 class="h3" style="font-weight: 300; margin-top: 1rem;">Recent Requests</h3>
					<div v-if="parse_nodes(account.expenses.nodes).length">
						<expense-list :expenses="parse_nodes(this.account.expenses.nodes)" :account="this.account"></expense-list>
					</div>
					<div v-else class="row" style="margin-top: 1rem;">
						<div class="col-md-4"></div>
						<div class="col-md-4">
							<figure>
								<img src="@/assets/getting-started1.svg" style="max-height: 480px; max-width: 480px;" alt="Nothing here yet">
								<figcaption class="h5">No completed requests yet.</figcaption>
							</figure>
						</div>
						<div class="col-md-4"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="loading">
		<img src='@/assets/ajax-loader.gif' alt="Loading..." class="loading-image" />
	</div>
</template>

<script>
	import ToastNotification from "@/components/ToastNotification.vue";
	import PendingExpenseList from "@/components/PendingExpenseList.vue";
	import ContributionList from "@/components/ContributionList.vue";
	import ExpenseList from "@/components/ExpenseList.vue";

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	});

	export default {
		components: { PendingExpenseList, ExpenseList, ContributionList, ToastNotification },
		name: "FundView",
		methods: {
			currency(amount) {
				return formatter.format(amount);
			},
			parse_nodes(nodes, arg = 10) {
				return nodes.filter(node => node.description != 'Host Fee' && !node.isRefunded && !node.isRefund).slice(0, arg);
			}
		},
		data() {
			return {
				account: {},
				user: {},
				votes: {},
				pending_expenses: [],
				title: null,
				is_authenticated: false,
				loaded: false,
				errors: null,
				paid_redirect: false
			}
		},
		created() {
			this.axios.get(`/api/${this.$route.params.type}/${this.$route.params.slug}`)
				.then((res) => res.data)
				.then((result) => {
					document.title = result.title;
					this.title = result.title;
					this.account = result.account;
					this.pending_expenses = result.pending_expenses;
					this.votes = result.votes;
					this.user = result.user;
					this.is_authenticated = result.user?.auth_info.isAuthenticated;
					this.loaded = true;

					if (!this.is_authenticated && this.account.slug != 'papertree-bedstuy' && this.account.slug != 'papertree-example') {
						window.location.replace("/?err=403");
					}
				})
				.catch((error) => {
					if(error.response) {
						if (error.response.status == 401 || error.response.status == 403){
							this.axios.get('/purge')
								.then((result) => {
									window.location.replace("/?err=403");
								})
						}
					}
					this.errors = error.message;
				});
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const success_redirect = urlParams.get('success');
			const success_status = urlParams.get('status');
			this.paid_redirect = success_redirect == 'true' && (success_status.toUpperCase() == 'PAID' || success_status.toUpperCase() == 'PROCESSING');
		},
		updated() {
			window.$(".toast").toast('show');
		}
	}
</script>

<style>
	.loading {
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.7;
		background-color: #fff;
		z-index: 99;
	}

	.loading-image {
		z-index: 100;
	}

	.sleek-button.secondary-button.disabled, .sleek-button.disabled {
		background-color: #fff;
		border-color: #989898;
		box-shadow: 0 0 0 0 #989898;
		color: #989898;
		cursor: not-allowed;
		pointer-events: none !important;
	}
</style>